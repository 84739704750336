import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './Home';
const App = () => {
  return (
    <div>
     <Home 
  title="Explore the Cryptocurrency Market Cap Trends of 2025" 
  description="Discover the latest insights and detailed analysis of cryptocurrency market capitalization for better investment decisions." 
  imageUrl="https://miro.medium.com/max/812/1*1xhuVp8f2WFUGUByHS8VTg.png" 
/>

      <h1>Welcome to the Market Cap Insights</h1>
      <p>This is your comprehensive guide to understanding cryptocurrency market capitalization and its impact on investments.</p>
    </div>
  );
}

export default App;