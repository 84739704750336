import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Utility functions to enforce recommended lengths
const truncateText = (text, maxLength) => 
  text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

const validateImageUrl = (url) => 
  url && url.startsWith('http') ? url : 'https://example.com/default-image.jpg';

const Home = ({ title, description, imageUrl }) => {
  // Recommended constraints
  const maxTitleLength = 60;
  const maxDescriptionLength = 160;

  const filteredTitle = truncateText(title, maxTitleLength);
  const filteredDescription = truncateText(description, maxDescriptionLength);
  const validatedImageUrl = validateImageUrl(imageUrl);

  return (
    <div>
      <Helmet>
        <title>{filteredTitle}</title>

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={filteredTitle} />
        <meta property="og:description" content={filteredDescription} />
        <meta property="og:image" content={validatedImageUrl} />
        <meta property="og:image:alt" content="Cryptocurrency Market Cap Overview" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.example.com/" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={filteredTitle} />
        <meta name="twitter:description" content={filteredDescription} />
        <meta name="twitter:image" content={validatedImageUrl} />
        <meta name="twitter:image:alt" content="Cryptocurrency Market Cap Overview" />
        <meta name="twitter:creator" content="@yourtwitterhandle" />
      </Helmet>
      <h1>Welcome to the Market Cap Insights</h1>
      <p>{filteredDescription}</p>
    </div>
  );
};

Home.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

Home.defaultProps = {
  title: 'Market Cap Insights: A Comprehensive Guide to Cryptocurrency',
  description: 'Learn about market capitalization and its significance in the world of cryptocurrency investments.',
  imageUrl: 'https://miro.medium.com/max/812/1*1xhuVp8f2WFUGUByHS8VTg.png',
};

export default Home;
